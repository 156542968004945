import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import AdminHeader from "../../common/AdminHeader";
import Footer from "../../common/Footer";
import axios from 'axios';
import { baseUrl } from '../../../Utility/constants';

const ViewSchoolData = () => {
  const { state } = useLocation();
  const { schoolData } = state || {};

  const [grantTypes, setGrantTypes] = useState([]);
  const [attachTypes, setAttachTypes] = useState([]);
  const [streams, setStreams] = useState([]);

  // Fetch Grant Types, Attach Types, and Streams on component load
  useEffect(() => {
    const fetchGrantTypes = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/CommonMaster/GetGrantTypeMasters`);
        setGrantTypes(response.data);
      } catch (error) {
        console.error("Error fetching Grant Types", error);
      }
    };

    const fetchAttachTypes = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/CommonMaster/GetAttachTypeMasters`);
        setAttachTypes(response.data);
      } catch (error) {
        console.error("Error fetching Attach Types", error);
      }
    };

    const fetchStreams = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/StreamMaster`);
        setStreams(response.data);
      } catch (error) {
        console.error("Error fetching Streams", error);
      }
    };

    fetchGrantTypes();
    fetchAttachTypes();
    fetchStreams();
  }, []);

  // Helper function to get name by ID
  const getNameById = (list, id) => {
    const item = list.find(el => el.id === parseInt(id, 10));  // Parse ID to integer
    return item ? item.name : 'N/A';
  };

  // Helper function to handle comma-separated IDs
  const getNamesFromIds = (list, ids) => {
    if (!ids) return 'N/A';  // Handle empty or undefined cases
    return ids.split(',').map(id => getNameById(list, id.trim())).join(', ');
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <h2 className="mb-4">School Details</h2>
        {schoolData ? (
          <Container>
            <Row className="mb-3">
              <Col md={4}><strong>Organization Name:</strong> {schoolData.organizationName}</Col>
              <Col md={4}><strong>Establishment Year:</strong> {schoolData.establishmentYear}</Col>
              <Col md={4}><strong>School/College Name:</strong> {schoolData.schoolOrCollegeName}</Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}><strong>Location:</strong> {schoolData.location}</Col>
              <Col md={4}><strong>UDISE No:</strong> {schoolData.udiseNo}</Col>
              <Col md={4}><strong>10th Index No:</strong> {schoolData.ssC10thIndexNo}</Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}><strong>12th Index No:</strong> {schoolData.hsC12thIndexNo}</Col>
              <Col md={4}>
                <strong>Grant Types:</strong> {getNamesFromIds(grantTypes, schoolData.grantTypes)}
              </Col>
              <Col md={4}>
                <strong>Streams:</strong> {getNamesFromIds(streams, schoolData.streams)}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}><strong>School Type:</strong> {schoolData.schoolType}</Col>
              <Col md={4}>
                <strong>Attach Types:</strong> {getNamesFromIds(attachTypes, schoolData.attachTypes)}
              </Col>
              <Col md={4}><strong>School Timing:</strong> {schoolData.schoolOrCollegeTiming}</Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}><strong>School Registration Number:</strong> {schoolData.schoolRegistrationNumber}</Col>
              <Col md={4}><strong>School Registration Date:</strong> {new Date(schoolData.schoolRegistrationDate).toLocaleDateString()}</Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}><strong>College Registration Number:</strong> {schoolData.collegeRegistrationNumber}</Col>
              <Col md={4}><strong>College Registration Date:</strong> {new Date(schoolData.collegeRegistrationDate).toLocaleDateString()}</Col>
              <Col md={4}><strong>Principal Name:</strong> {schoolData.principalName}</Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}><strong>Principal Contact No:</strong> {schoolData.principalContactNo}</Col>
              <Col md={4}><strong>Email:</strong> {schoolData.email}</Col>
            </Row>
          </Container>
        ) : (
          <p>No details available</p>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ViewSchoolData;

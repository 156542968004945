import RouterPage from "./components/routes/RouterPage";
import './App.css';

function App() {
  return (
    <RouterPage />
  );
}

export default App;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../../common/Footer";
import { baseUrl } from "../../../Utility/constants";

const AttachType = () => {
  const [attachTypes, setAttachTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentAttachType, setCurrentAttachType] = useState({
    id: 0,
    name: "",
  });

  const apiUrl = `${baseUrl}/api/AttachTypeMaster`;

  useEffect(() => {
    fetchAttachTypes();
  }, []);

  const fetchAttachTypes = async () => {
    try {
      const response = await axios.get(apiUrl);
      setAttachTypes(response.data);
    } catch (error) {
      toast.error("Failed to fetch attach types");
    }
  };

  const handleAdd = () => {
    setCurrentAttachType({ id: 0, name: "" });
    setIsEditing(false);
    setShowModal(true);
  };

  const handleEdit = (attachType) => {
    setCurrentAttachType(attachType);
    setIsEditing(true);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await axios.delete(`${apiUrl}/${id}`);
        toast.success("Attach type deleted successfully");
        fetchAttachTypes();
      } catch (error) {
        toast.error("Failed to delete attach type");
      }
    }
  };

  const handleSave = async () => {
    try {
      if (isEditing) {
        await axios.put(`${apiUrl}/${currentAttachType.id}`, currentAttachType);
        toast.success("Attach type updated successfully");
      } else {
        await axios.post(apiUrl, currentAttachType);
        toast.success("Attach type added successfully");
      }
      fetchAttachTypes();
      setShowModal(false);
    } catch (error) {
      toast.error("Failed to save attach type");
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>Manage Attach Types</h2>
          <Button variant="primary" onClick={handleAdd}>
            Add New Attach Type
          </Button>
        </div>
        <table className="table table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {attachTypes.map((attachType) => (
              <tr key={attachType.id}>
                <td>{attachType.id}</td>
                <td>{attachType.name}</td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => handleEdit(attachType)}
                  >
                    Edit
                  </Button>{" "}
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(attachType.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Footer />
        </div>
      </div>

      {/* Add/Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Attach Type" : "Add Attach Type"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="attachTypeName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={currentAttachType.name}
                onChange={(e) =>
                  setCurrentAttachType({
                    ...currentAttachType,
                    name: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default AttachType;

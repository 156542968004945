import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Form } from "react-bootstrap";
import AdminHeader from "../../common/AdminHeader";
import Footer from "../../common/Footer";
import { baseUrl } from "../../../Utility/constants";

const State = () => {
  const [states, setStates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState({
    id: 0,
    name: "",
  });
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/StateMaster`);
      setStates(response.data);
    } catch (error) {
      toast.error("Failed to fetch states.");
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormState({ id: 0, name: "" });
    setEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleEdit = (state) => {
    setFormState(state);
    setEditMode(true);
    handleShowModal();
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this state?")) {
      try {
        await axios.delete(`${baseUrl}/api/StateMaster/${id}`);
        toast.success("State deleted successfully.");
        fetchStates();
      } catch (error) {
        toast.error("Failed to delete state.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editMode) {
        await axios.put(
          `${baseUrl}/api/StateMaster/${formState.id}`,
          formState
        );
        toast.success("State updated successfully.");
      } else {
        await axios.post(`${baseUrl}/api/StateMaster`, formState);
        toast.success("State added successfully.");
      }
      fetchStates();
      handleCloseModal();
    } catch (error) {
      toast.error("Failed to save state.");
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>State</h2>
          <Button variant="primary" onClick={handleShowModal}>
            Add State
          </Button>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>State Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {states.map((state, index) => (
              <tr key={state.id}>
                <td>{index + 1}</td>
                <td>{state.name}</td>
                <td>
                  <Button variant="warning" onClick={() => handleEdit(state)}>
                    Edit
                  </Button>{" "}
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(state.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Edit State" : "Add State"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>State Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formState.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {editMode ? "Update" : "Add"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer />

      <Footer />
    </>
  );
};

export default State;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Footer from "../../common/Footer";
import { loggedUser, baseUrl } from "../../../Utility/constants";

const SchoolContactAffiliationDetails = () => {
  const [formData, setFormData] = useState({
    schoolId: "",
    it: false,
    bifocal: false,
    minority: false,
    email: "",
    schoolOrCollegeContactNo: "", // Added field
    websiteName: "", // Added field
    principalName: "", // Added field
    principalContactNo: "", // Added field
    address: "", // Added field
    country: "",
    state: "",
    district: "",
    taluka: "",
    city: "",
    pincode: "", // Added field
    minorityType: "NA",
    createdBy: loggedUser,
    updatedBy: loggedUser,
  });

  const [schools, setSchools] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Fetch school, state, district, taluka, and city data
    fetchData("SchoolGeneralInfo", setSchools);
    fetchData("StateMaster", setStates);
  }, []);

  const fetchData = async (endpoint, setState) => {
    try {
      const response = await axios.get(`${baseUrl}/api/${endpoint}`);
      setState(response.data);
    } catch (error) {
      toast.error(`Failed to load ${endpoint}`);
    }
  };

  const handleStateChange = async (stateId) => {
    setFormData({ ...formData, state: stateId, district: "", taluka: "", city: "" });
    try {
      const response = await axios.get(`${baseUrl}/api/DistrictMaster/GetDistrictByState?id=${stateId}`);
      setDistricts(response.data);
    } catch (error) {
      toast.error("Failed to load districts");
    }
  };

  const handleDistrictChange = async (districtId) => {
    setFormData({ ...formData, district: districtId, taluka: "", city: "" });
    try {
      const response = await axios.get(`${baseUrl}/api/TalukaMaster/GetTalukaByDistrict?id=${districtId}`);
      setTalukas(response.data);
    } catch (error) {
      toast.error("Failed to load talukas");
    }
  };

  const handleTalukaChange = async (talukaId) => {
    setFormData({ ...formData, taluka: talukaId, city: "" });
    try {
      const response = await axios.get(`${baseUrl}/api/CityVillageMaster/GetCityVillageBtTaluka?id=${talukaId}`);
      setCities(response.data);
    } catch (error) {
      toast.error("Failed to load cities");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseUrl}/api/ContactAffiliationInfo`, formData);
      toast.success("Record saved successfully!");
      setFormData({
        schoolId: "",
        it: false,
        bifocal: false,
        minority: false,
        email: "",
        schoolOrCollegeContactNo: "", 
        websiteName: "", 
        principalName: "", 
        principalContactNo: "", 
        address: "", 
        country: "",
        state: "",
        district: "",
        taluka: "",
        city: "",
        pincode: "", 
        minorityType: "NA",
        createdBy: loggedUser,
        updatedBy: loggedUser,
      });
    } catch (error) {
      toast.error("Failed to save record.");
    }
  };

  return (
    <>
      <AdminHeader />
      <Container className="mt-5 mb-5">       
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>School</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.schoolId}
                  onChange={(e) => setFormData({ ...formData, schoolId: e.target.value })}
                  required
                >
                  <option value="">Select School</option>
                  {schools.map((school) => (
                    <option key={school.schoolId} value={school.schoolId}>
                      {school.schoolOrCollegeName} 
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>School or College Contact No.</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.schoolOrCollegeContactNo}
                  onChange={(e) => setFormData({ ...formData, schoolOrCollegeContactNo: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Website Name (URL)</Form.Label>
                <Form.Control
                  type="url"
                  value={formData.websiteName}
                  onChange={(e) => setFormData({ ...formData, websiteName: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Principal Name</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.principalName}
                  onChange={(e) => setFormData({ ...formData, principalName: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Principal Contact No.</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.principalContactNo}
                  onChange={(e) => setFormData({ ...formData, principalContactNo: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  value={formData.address}
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.pincode}
                  onChange={(e) => setFormData({ ...formData, pincode: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Existing State, District, Taluka, City dropdowns */}
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.state}
                  onChange={(e) => handleStateChange(e.target.value)}
                  required
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>District</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.district}
                  onChange={(e) => handleDistrictChange(e.target.value)}
                  required
                  disabled={!formData.state}
                >
                  <option value="">Select District</option>
                  {districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Taluka</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.taluka}
                  onChange={(e) => handleTalukaChange(e.target.value)}
                  required
                  disabled={!formData.district}
                >
                  <option value="">Select Taluka</option>
                  {talukas.map((taluka) => (
                    <option key={taluka.id} value={taluka.id}>
                      {taluka.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.city}
                  onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                  required
                  disabled={!formData.taluka}
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          {/* Additional fields for Bifocal, IT, Minority */}
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Bifocal"
                  checked={formData.bifocal}
                  onChange={(e) => setFormData({ ...formData, bifocal: e.target.checked })}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="IT"
                  checked={formData.it}
                  onChange={(e) => setFormData({ ...formData, it: e.target.checked })}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Minority"
                  checked={formData.minority}
                  onChange={(e) => setFormData({ ...formData, minority: e.target.checked })}
                />
              </Form.Group>
            </Col>
          </Row>

          {formData.minority && (
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Minority Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.minorityType}
                    onChange={(e) => setFormData({ ...formData, minorityType: e.target.value })}
                    required
                  >
                    <option value="NA">NA</option>
                    <option value="Religious">Religious</option>
                    <option value="Linguistic">Linguistic</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        <ToastContainer />
      </Container>
      <Footer />
    </>
  );
};

export default SchoolContactAffiliationDetails;

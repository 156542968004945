import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import MainHeader from "../../common/MainHeader";
import { FooterName, baseUrl } from "../../../Utility/constants";
import { useNavigate } from "react-router-dom";

const StudentRegistration = () => {
  const [schoolId, setSchoolId] = useState("");
  const [schoolList, setSchoolList] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "", 
    lastName: "",
    gender: "",
    studentContactNumber: "",
    studentEmail: "",
    password: ""
  });

  const navigate = useNavigate();

  // Fetch school details
  useEffect(() => {
    axios
      .get(`${baseUrl}/api/SchoolGeneralInfo`)
      .then((response) => {
        setSchoolList(response.data);
      })
      .catch((error) => {
        toast.error("Failed to load school details.");
      });
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = { ...formData, schoolId };

    axios
      .post(`${baseUrl}/api/PersonalDetails`, postData)
      .then((response) => {
        toast.success("Registration successful!");        
      })
      .catch((error) => {
        toast.error("Failed to register. Please try again.");
      });
  };

  return (
    <div>
      <MainHeader />
      <div className="container mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">Student Registration</div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  {/* School Dropdown */}
                  <div className="mb-3">
                    <label htmlFor="schoolId" className="form-label">
                      Select School
                    </label>
                    <select
                      id="schoolId"
                      name="schoolId"
                      className="form-select"
                      value={schoolId}
                      onChange={(e) => setSchoolId(e.target.value)}
                      required
                    >
                      <option value="">--Select School--</option>
                      {schoolList.map((school) => (
                        <option key={school.schoolId} value={school.schoolId}>
                          {school.schoolOrCollegeName}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* First Name */}
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="form-control"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* Middle Name */}
                  <div className="mb-3">
                    <label htmlFor="middleName" className="form-label">
                      Middle Name
                    </label>
                    <input
                      type="text"
                      id="middleName"
                      name="middleName"
                      className="form-control"
                      value={formData.middleName}
                      onChange={handleChange}
                    />
                  </div>

                  {/* Last Name */}
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="form-control"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* Gender */}
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label">
                      Gender
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      className="form-select"
                      value={formData.gender}
                      onChange={handleChange}
                      required
                    >
                      <option value="">--Select Gender--</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  {/* Student Contact Number */}
                  <div className="mb-3">
                    <label htmlFor="studentContactNumber" className="form-label">
                      Contact Number
                    </label>
                    <input
                      type="text"
                      id="studentContactNumber"
                      name="studentContactNumber"
                      className="form-control"
                      value={formData.studentContactNumber}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* Student Email */}
                  <div className="mb-3">
                    <label htmlFor="studentEmail" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      id="studentEmail"
                      name="studentEmail"
                      className="form-control"
                      value={formData.studentEmail}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* Password */}
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* Submit Button */}
                  <button type="submit" className="btn btn-primary me-2">
                    Register
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <footer
        className="fixed-bottom"
        style={{
          backgroundColor: "black",
          color: "#6c757d",
          textAlign: "center",
          padding: "10px 0",
          boxShadow: "0 -1px 5px rgba(0, 0, 0, 0.1)",
          width: "100%",
        }}
      >
        <div className="container">
          <p className="mb-0" style={{ color: "white" }}>
            Copyright &copy; 2017-{new Date().getFullYear()} {FooterName} | All rights
            reserved.
          </p>
        </div>
      </footer>
      
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default StudentRegistration;

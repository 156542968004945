import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../../common/Footer";
import { baseUrl } from "../../../Utility/constants";

const Standard = () => {
  const [standards, setStandards] = useState([]);
  const [schools, setSchools] = useState([]);
  const [attachTypes, setAttachTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add"); // "add" or "edit"
  const [currentItem, setCurrentItem] = useState({ id: 0, standardName: "", schoolId: "", attachTypeId: "" });

  useEffect(() => {
    fetchStandards();
    fetchSchools();
    fetchAttachTypes();
  }, []);

  const fetchStandards = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/StandardMaster`);
      setStandards(response.data);
    } catch (error) {
      toast.error("Failed to fetch standards");
    }
  };

  const fetchSchools = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/SchoolGeneralInfo`);
      setSchools(response.data);
    } catch (error) {
      toast.error("Failed to fetch schools");
    }
  };

  const fetchAttachTypes = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/CommonMaster/GetAttachTypeMasters`);
      setAttachTypes(response.data);
    } catch (error) {
      toast.error("Failed to fetch attach types");
    }
  };

  const handleAdd = () => {
    setCurrentItem({ id: 0, standardName: "", schoolId: "", attachTypeId: "" });
    setModalMode("add");
    setShowModal(true);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setModalMode("edit");
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await axios.delete(`${baseUrl}/api/StandardMaster/${id}`);
        toast.success("Item deleted successfully");
        fetchStandards();
      } catch (error) {
        toast.error("Failed to delete item");
      }
    }
  };

  const handleSave = async () => {
    try {
      if (modalMode === "add") {
        await axios.post(`${baseUrl}/api/StandardMaster`, { ...currentItem, id: 0 });
        toast.success("Item added successfully");
      } else {
        await axios.put(`${baseUrl}/api/StandardMaster/${currentItem.id}`, currentItem);
        toast.success("Item updated successfully");
      }
      fetchStandards();
      setShowModal(false);
    } catch (error) {
      toast.error("Failed to save item");
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>Standard</h2>
          <Button variant="primary" onClick={handleAdd}>Add New</Button>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
            <th>#</th>            
              <th>Name</th>
              <th>School</th>
              <th>Attach Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {standards.map((item, index) => (
              <tr key={item.id}>
                 <td>{index + 1}</td>             
                <td>{item.standardName}</td>
                <td>{schools.find((s) => s.schoolId === item.schoolID)?.schoolOrCollegeName || "N/A"}</td>
                <td>{attachTypes.find((a) => a.id === item.attachTypeID)?.name || "N/A"}</td>
                <td>
                  <Button variant="warning" onClick={() => handleEdit(item)}>Edit</Button>&nbsp;
                  <Button variant="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalMode === "add" ? "Add New" : "Edit"} Standard</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formStandardName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={currentItem.standardName}
                  onChange={(e) => setCurrentItem({ ...currentItem, standardName: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formSchool">
                <Form.Label>School</Form.Label>
                <Form.Control
                  as="select"
                  value={currentItem.schoolId}
                  onChange={(e) => setCurrentItem({ ...currentItem, schoolId: e.target.value })}
                >
                  <option value="">Select School</option>
                  {schools.map((school) => (
                    <option key={school.schoolId} value={school.schoolId}>
                      {school.schoolOrCollegeName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formAttachType">
                <Form.Label>Attach Type</Form.Label>
                <Form.Control
                  as="select"
                  value={currentItem.attachTypeId}
                  onChange={(e) => setCurrentItem({ ...currentItem, attachTypeId: e.target.value })}
                >
                  <option value="">Select Attach Type</option>
                  {attachTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
            <Button variant="primary" onClick={handleSave}>{modalMode === "add" ? "Add" : "Save"}</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default Standard;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Form } from "react-bootstrap";
import AdminHeader from "../../common/AdminHeader";
import Footer from "../../common/Footer";
import { baseUrl } from "../../../Utility/constants";

const CityVillage = () => {
  const [cities, setCities] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState({
    id: 0,
    talukaID: 0,
    name: "",
  });
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchCities();
    fetchTalukas();
  }, []);

  const fetchCities = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/CityVillageMaster`);
      setCities(response.data);
    } catch (error) {
      toast.error("Failed to fetch cities.");
    }
  };

  const fetchTalukas = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/TalukaMaster`);
      setTalukas(response.data);
    } catch (error) {
      toast.error("Failed to fetch talukas.");
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormState({ id: 0, talukaID: 0, name: "" });
    setEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleEdit = (city) => {
    setFormState(city);
    setEditMode(true);
    handleShowModal();
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this city?")) {
      try {
        await axios.delete(`${baseUrl}/api/CityVillageMaster/${id}`);
        toast.success("City deleted successfully.");
        fetchCities();
      } catch (error) {
        toast.error("Failed to delete city.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formState.talukaID === 0) {
      toast.error("Please select a Taluka.");
      return;
    }
    try {
      if (editMode) {
        await axios.put(`${baseUrl}/api/CityVillageMaster/${formState.id}`, formState);
        toast.success("City updated successfully.");
      } else {
        await axios.post(`${baseUrl}/api/CityVillageMaster`, formState);
        toast.success("City added successfully.");
      }
      fetchCities();
      handleCloseModal();
    } catch (error) {
      toast.error("Failed to save city.");
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>City</h2>
          <Button variant="primary" onClick={handleShowModal}>
            Add City
          </Button>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
            <th>#</th>   
              <th>Taluka</th>
              <th>City Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {cities.map((city, index) => (
              <tr key={city.id}>
                <td>{index + 1}</td>         
                <td>{talukas.find((taluka) => taluka.id === city.talukaID)?.name}</td>
                <td>{city.name}</td>
                <td>
                  <Button variant="warning" onClick={() => handleEdit(city)}>
                    Edit
                  </Button>{" "}
                  <Button variant="danger" onClick={() => handleDelete(city.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Edit City" : "Add City"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Taluka</Form.Label>
              <Form.Control
                as="select"
                name="talukaID"
                value={formState.talukaID}
                onChange={handleInputChange}
                required
              >
                <option value="0">Select Taluka</option>
                {talukas.map((taluka) => (
                  <option key={taluka.id} value={taluka.id}>
                    {taluka.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>City Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formState.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {editMode ? "Update" : "Add"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer />

      <Footer />
    </>
  );
};

export default CityVillage;

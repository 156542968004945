import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../../common/Footer";
import { baseUrl } from "../../../Utility/constants";

const BloodGroup = () => {
  const [bloodGroups, setBloodGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentBloodGroup, setCurrentBloodGroup] = useState({ id: 0, name: "" });
  const [isEdit, setIsEdit] = useState(false);

  const apiUrl = `${baseUrl}/api/BloodGroupMaster`;

  useEffect(() => {
    fetchBloodGroups();
  }, []);

  const fetchBloodGroups = async () => {
    try {
      const response = await axios.get(apiUrl);
      setBloodGroups(response.data);
    } catch (error) {
      toast.error("Failed to fetch blood groups");
    }
  };

  const handleShowModal = (bloodGroup = { id: 0, name: "" }) => {
    setCurrentBloodGroup(bloodGroup);
    setIsEdit(!!bloodGroup.id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentBloodGroup({ id: 0, name: "" });
  };

  const handleChange = (e) => {
    setCurrentBloodGroup({ ...currentBloodGroup, name: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      if (isEdit) {
        await axios.put(`${apiUrl}/${currentBloodGroup.id}`, currentBloodGroup);
        toast.success("Blood group updated successfully");
      } else {
        await axios.post(apiUrl, currentBloodGroup);
        toast.success("Blood group added successfully");
      }
      fetchBloodGroups();
      handleCloseModal();
    } catch (error) {
      toast.error("Failed to save blood group");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this blood group?")) {
      try {
        await axios.delete(`${apiUrl}/${id}`);
        toast.success("Blood group deleted successfully");
        fetchBloodGroups();
      } catch (error) {
        toast.error("Failed to delete blood group");
      }
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Manage Blood Groups</h3>
          <Button variant="primary" onClick={() => handleShowModal()}>
            Add Blood Group
          </Button>
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {bloodGroups.map((bloodGroup) => (
              <tr key={bloodGroup.id}>
                <td>{bloodGroup.id}</td>
                <td>{bloodGroup.name}</td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => handleShowModal(bloodGroup)}
                    className="me-2"
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(bloodGroup.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit Blood Group" : "Add Blood Group"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Blood Group Name</Form.Label>
              <Form.Control
                type="text"
                value={currentBloodGroup.name}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEdit ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
      <ToastContainer />
    </>
  );
};

export default BloodGroup;

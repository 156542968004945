import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../../common/Footer";
import { baseUrl } from "../../../Utility/constants";

const Caste = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentSubCategory, setCurrentSubCategory] = useState({
    id: 0,
    name: "",
    categoryId: "",
  });

  useEffect(() => {
    fetchSubCategories();
    fetchCategories();
  }, []);

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/CasteMaster`);
      setSubCategories(response.data);
    } catch (error) {
      toast.error("Failed to fetch subcategories");
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/CategoryMaster`);
      setCategories(response.data);
    } catch (error) {
      toast.error("Failed to fetch categories");
    }
  };

  const handleShowModal = (subCategory = { id: 0, name: "", categoryId: "" }) => {
    setCurrentSubCategory(subCategory);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentSubCategory({ id: 0, name: "", categoryId: "" });
  };

  const handleSave = async () => {
    try {
      if (currentSubCategory.id === 0) {
        await axios.post(`${baseUrl}/api/CasteMaster`, currentSubCategory);
        toast.success("SubCategory added successfully");
      } else {
        await axios.put(`${baseUrl}/api/CasteMaster/${currentSubCategory.id}`, currentSubCategory);
        toast.success("SubCategory updated successfully");
      }
      fetchSubCategories();
      handleCloseModal();
    } catch (error) {
      toast.error("Failed to save subcategory");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this subcategory?")) {
      try {
        await axios.delete(`${baseUrl}/api/CasteMaster/${id}`);
        toast.success("SubCategory deleted successfully");
        fetchSubCategories();
      } catch (error) {
        toast.error("Failed to delete subcategory");
      }
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>Manage Caste</h2>
          <Button variant="primary" onClick={() => handleShowModal()}>
            Add Caste
          </Button>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
            <th>#</th>
              <th>Name</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {subCategories.map((subCategory, index) => (
              <tr key={subCategory.id}>
                 <td>{index + 1}</td>
                <td>{subCategory.name}</td>
                <td>{categories.find((c) => c.id === subCategory.categoryID)?.name}</td>
                <td>
                  <Button variant="warning" onClick={() => handleShowModal(subCategory)}>
                    Edit
                  </Button>{" "}
                  <Button variant="danger" onClick={() => handleDelete(subCategory.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
       
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{currentSubCategory.id === 0 ? "Add Caste" : "Edit Caste"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>            
            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                value={currentSubCategory.categoryID}
                onChange={(e) => setCurrentSubCategory({ ...currentSubCategory, categoryId: e.target.value })}
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={currentSubCategory.name}
                onChange={(e) => setCurrentSubCategory({ ...currentSubCategory, name: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default Caste;

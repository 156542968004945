import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Footer from "../../common/Footer";
import { loggedUser } from "../../../Utility/constants";
import { baseUrl } from "../../../Utility/constants";

const AddSchool = () => {
  const [formData, setFormData] = useState({
    schoolId: 0,
    organizationName: "",
    establishmentYear: "",
    schoolOrCollegeName: "",
    isNightSchoolOrCollege: false,
    location: "",  // for location dropdown
    udiseNo: "",
    ssC10thIndexNo: "",
    hsC12thIndexNo: "",
    attachTypes: [],
    grantTypes: [],  // multiple selection
    streams: [],    // multiple selection
    schoolType: "",
    schoolOrCollegeTiming: "",  // for school timing dropdown
    schoolRegistrationNumber: "",
    schoolRegistrationDate: "",
    collegeRegistrationNumber: "",
    collegeRegistrationDate: "",
    createdBy: loggedUser,
    updatedBy: loggedUser,
  });

  const [dropdowns, setDropdowns] = useState({
    grantTypes: [],
    streams: [],
    schoolTypes: [],
    attachTypes: [],  // attach types added here   
    locations: [],  // for location dropdown
    schoolTimings: []  // for school timing dropdown
  });

  useEffect(() => {
    const fetchDropdowns = async () => {
      try {
        const [
          grantTypeRes,
          streamRes,
          schoolTypeRes,
          attachTypeRes,  // for attach types
          locationRes,  // for location
          schoolTimingRes  // for school timings
        ] = await Promise.all([
          axios.get(`${baseUrl}/api/CommonMaster/GetGrantTypeMasters`),
          axios.get(`${baseUrl}/api/StreamMaster`),
          axios.get(`${baseUrl}/api/CommonMaster/GetSchoolTypeMasters`),
          axios.get(`${baseUrl}/api/CommonMaster/GetAttachTypeMasters  `),  // fetching attach types        
          axios.get(`${baseUrl}/api/CommonMaster/GetLocationMasters  `), // fetching locations
          axios.get(`${baseUrl}/api/CommonMaster/GetSchoolTimingsMasters`) // fetching school timings
        ]);

        setDropdowns({
          grantTypes: grantTypeRes.data,
          streams: streamRes.data,
          schoolTypes: schoolTypeRes.data,
          attachTypes: attachTypeRes.data,  // setting attach types data
          locations: locationRes.data,  // setting location data
          schoolTimings: schoolTimingRes.data  // setting school timings data
        });
      } catch (error) {
        console.log("Failed to fetch dropdown data");
      }
    };

    fetchDropdowns();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handleMultiSelectChange = (e) => {
    const { name, options } = e.target;
    // const selectedValues = Array.from(options)
    //   .filter(option => option.selected)
    //   .map(option => ({
    //     id: Number(option.value), // Use Number to match API expected ID format
    //     name: option.text,
    //     isActive: 1 // Assuming default active status is true
    //   }));

    const selectedValues = Array.from(options)
    .filter(option => option.selected)
    .map(option => Number(option.value))
    .join(',');

    setFormData({
      ...formData,
      [name]: selectedValues
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const formattedData = {
      //   ...formData,
      //   grantTypes: formData.grantTypes.map(g => ({ id: g.id, name: g.name, isActive: g.isActive })),
      //   streams: formData.streams.map(s => ({ id: s.id, name: s.name, isActive: s.isActive }))
      // };
      
      await axios.post(`${baseUrl}/api/SchoolGeneralInfo`, formData);
      toast.success("School information added successfully");
      setFormData({
        schoolId: 0,
        organizationName: "",
        establishmentYear: "",
        schoolOrCollegeName: "",
        isNightSchoolOrCollege: false,
        location: "",
        udiseNo: "",
        ssC10thIndexNo: "",
        hsC12thIndexNo: "",
        attachTypes: [],
        grantTypes: [],
        streams: [],
        schoolType: "",
        attachType: "",       
        schoolOrCollegeTiming: "",
        schoolRegistrationNumber: "",
        schoolRegistrationDate: "",
        collegeRegistrationNumber: "",
        collegeRegistrationDate: "",
        createdBy: loggedUser,
        updatedBy: loggedUser
      });
    } catch (error) {
      toast.error("Failed to add school information");
    }
  };

  return (
    <>
      <AdminHeader />
      <Container className="mt-5 mb-5">      
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formOrganizationName">
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  type="text"
                  name="organizationName"
                  value={formData.organizationName}
                  onChange={handleInputChange}
                  placeholder="Enter organization name"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formEstablishmentYear">
                <Form.Label>Establishment Year</Form.Label>
                <Form.Control
                  type="text"
                  name="establishmentYear"
                  value={formData.establishmentYear}
                  onChange={handleInputChange}
                  placeholder="Enter establishment year"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formSchoolOrCollegeName">
                <Form.Label>School/College Name</Form.Label>
                <Form.Control
                  type="text"
                  name="schoolOrCollegeName"
                  value={formData.schoolOrCollegeName}
                  onChange={handleInputChange}
                  placeholder="Enter school/college name"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formIsNightSchoolOrCollege">
                <Form.Check
                  type="checkbox"
                  name="isNightSchoolOrCollege"
                  checked={formData.isNightSchoolOrCollege}
                  onChange={handleInputChange}
                  label="Is Night School/College?"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formLocation">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  as="select"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                >
                  <option value="">Select Location</option>
                  {dropdowns.locations.map(loc => (
                    <option key={loc.id} value={loc.locationName}>{loc.locationName}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formUdiseNo">
                <Form.Label>UDISE No</Form.Label>
                <Form.Control
                  type="text"
                  name="udiseNo"
                  value={formData.udiseNo}
                  onChange={handleInputChange}
                  placeholder="Enter UDISE No"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formSSC10thIndexNo">
                <Form.Label>SSC 10th Index No</Form.Label>
                <Form.Control
                  type="text"
                  name="ssC10thIndexNo"
                  value={formData.ssC10thIndexNo}
                  onChange={handleInputChange}
                  placeholder="Enter SSC 10th Index No"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formHSC12thIndexNo">
                <Form.Label>HSC 12th Index No</Form.Label>
                <Form.Control
                  type="text"
                  name="hsC12thIndexNo"
                  value={formData.hsC12thIndexNo}
                  onChange={handleInputChange}
                  placeholder="Enter HSC 12th Index No"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formSchoolType">
                <Form.Label>School Type</Form.Label>
                <Form.Control
                  as="select"
                  name="schoolType"
                  value={formData.schoolType}
                  onChange={handleInputChange}
                >
                  <option value="">Select School Type</option>
                  {dropdowns.schoolTypes.map(type => (
                    <option key={type.id} value={type.name}>{type.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formAttachType">
                <Form.Label>Attach Type</Form.Label>
                <Form.Control
                  as="select"
                  name="attachTypes"
                  multiple                
                  onChange={handleMultiSelectChange}
                >
                  {/* <option value="">Select Attach Type</option> */}
                  {dropdowns.attachTypes.map(attach => (
                    <option key={attach.id} value={attach.id}>{attach.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formSchoolOrCollegeTiming">
                <Form.Label>School/College Timing</Form.Label>
                <Form.Control
                  as="select"
                  name="schoolOrCollegeTiming"
                  value={formData.schoolOrCollegeTiming}
                  onChange={handleInputChange}
                >
                  <option value="">Select Timing</option>
                  {dropdowns.schoolTimings.map(timing => (
                    <option key={timing.id} value={timing.timing}>{timing.timing}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formSSC10thIndexNo">
                <Form.Label>School Recognition Number</Form.Label>
                <Form.Control
                  type="text"
                  name="schoolRegistrationNumber"
                  value={formData.schoolRegistrationNumber}
                  onChange={handleInputChange}
                  placeholder="School Recognition Number"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
          <Col md={4}>
              <Form.Group controlId="formSSC10thIndexNo">
                <Form.Label>School Recognition Date</Form.Label>
                <Form.Control
                  type="Date"
                  name="schoolRegistrationDate"
                  value={formData.schoolRegistrationDate}
                  onChange={handleInputChange}
                  placeholder="School Recognition Date"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formSSC10thIndexNo">
                <Form.Label>College Recognition Number</Form.Label>
                <Form.Control
                  type="text"
                  name="collegeRegistrationNumber"
                  value={formData.collegeRegistrationNumber}
                  onChange={handleInputChange}
                  placeholder="College Recognition Number"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formSSC10thIndexNo">
                <Form.Label>College Recognition Date</Form.Label>
                <Form.Control
                  type="Date"
                  name="collegeRegistrationDate"
                  value={formData.collegeRegistrationDate}
                  onChange={handleInputChange}
                  placeholder="College Recognition Date"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
          <Col md={4}>
              <Form.Group controlId="formGrantType">
                <Form.Label>Grant Type</Form.Label>
                <Form.Control
                  as="select"
                  name="grantTypes"
                  multiple
                  onChange={handleMultiSelectChange}
                >
                  {dropdowns.grantTypes.map(grant => (
                    <option key={grant.id} value={grant.id}>{grant.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
			
			 <Col md={4}>
              <Form.Group controlId="formStreams">
                <Form.Label>Streams</Form.Label>
                <Form.Control
                  as="select"
                  name="streams"
                  multiple
                  onChange={handleMultiSelectChange}
                >
                  {dropdowns.streams.map(stream => (
                    <option key={stream.id} value={stream.id}>{stream.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
			
			
           
          </Row>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        <ToastContainer />       
      </Container>
      <Footer />
    </>
  );
};

export default AddSchool;

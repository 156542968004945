import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Modal, Button, Table, Form } from "react-bootstrap";
import Footer from "../../common/Footer";
import { loggedUser } from "../../../Utility/constants";
import { useNavigate } from 'react-router-dom';
import { baseUrl } from "../../../Utility/constants";

const SchoolList = () => {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [formData, setFormData] = useState({
    schoolId: 0,
    organizationName: "",
    establishmentYear: "",
    schoolOrCollegeName: "",
    isNightSchoolOrCollege: false,
    location: "",
    udiseNo: "",
    ssC10thIndexNo: "",
    hsC12thIndexNo: "",
    grantType: "",
    streams: "",
    schoolType: "",
    attachType: "",
    schoolOrCollegeTiming: "",
    createdBy: loggedUser,
    updatedBy: loggedUser
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/SchoolGeneralInfo`);
        setSchools(response.data);
      } catch (error) {
        toast.error("Failed to fetch school data");
      }
    };

    fetchSchools();
  }, []);

  const handleView = async (schoolId) => {
    try {
      const response = await axios.get(`${baseUrl}/api/SchoolGeneralInfo/GetSchoolInfo?schoolId=${schoolId}`);
      navigate('/school-details', { state: { schoolData: response.data } });
    } catch (error) {
      toast.error("Failed to fetch school details");
    }
  };

  const handleEdit = (school) => {
    setFormData(school);
    setShowEditModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this school?")) {
      try {
        await axios.delete(`${baseUrl}/api/SchoolGeneralInfo/${id}`);
        toast.success("School deleted successfully");
        setSchools(schools.filter(school => school.schoolId !== id));
      } catch (error) {
        toast.error("Failed to delete school");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${baseUrl}/api/SchoolGeneralInfo/${formData.schoolId}`, formData);
      toast.success("School updated successfully");
      setShowEditModal(false);
      setSchools(schools.map(school => school.schoolId === formData.schoolId ? formData : school));
    } catch (error) {
      toast.error("Failed to update school");
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <h2>School List</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Organization Name</th>
              <th>Establishment Year</th>
              <th>School/College Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {schools.map(school => (
              <tr key={school.schoolId}>
                <td>{school.organizationName}</td>
                <td>{school.establishmentYear}</td>
                <td>{school.schoolOrCollegeName}</td>
                <td>
                  <Button variant="info" onClick={() => handleView(school.schoolId)} className="me-2">View</Button>
                  <Button variant="warning" onClick={() => handleEdit(school)} className="me-2">Edit</Button>
                  <Button variant="danger" onClick={() => handleDelete(school.schoolId)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* Edit Modal */}
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit School</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleEditSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  type="text"
                  name="organizationName"
                  value={formData.organizationName}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Establishment Year</Form.Label>
                <Form.Control
                  type="number"
                  name="establishmentYear"
                  value={formData.establishmentYear}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>School/College Name</Form.Label>
                <Form.Control
                  type="text"
                  name="schoolOrCollegeName"
                  value={formData.schoolOrCollegeName}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {/* Add more fields as needed */}
              <Button variant="primary" type="submit">Save Changes</Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default SchoolList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Form, Table } from "react-bootstrap";
import AdminHeader from "../../common/AdminHeader";
import Footer from "../../common/Footer";
import { loggedUser, baseUrl } from "../../../Utility/constants";

const ManageAdmin = () => {
  const [admins, setAdmins] = useState([]);
  const [schools, setSchools] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState({
    id: 0,
    schoolId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    createdBy: loggedUser,
    updatedBy: loggedUser,
  });

  const [isEdit, setIsEdit] = useState(false);

  // Fetch Admins
  const fetchAdmins = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/Admin`);
      setAdmins(response.data);
    } catch (error) {
      toast.error("Error fetching admins");
    }
  };

  // Fetch Schools
  const fetchSchools = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/SchoolGeneralInfo`);
      setSchools(response.data);
    } catch (error) {
      toast.error("Error fetching schools");
    }
  };

  useEffect(() => {
    fetchAdmins();
    fetchSchools();
  }, []);

  // Handle Add/Edit Admin
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isEdit) {
        // Update Admin
        await axios.put(`${baseUrl}/api/Admin/${currentAdmin.id}`, currentAdmin);
        toast.success("Admin updated successfully");
      } else {
        // Add Admin
        await axios.post(`${baseUrl}/api/Admin`, currentAdmin);
        toast.success("Admin added successfully");
      }

      setShowModal(false);
      fetchAdmins(); // Refresh admin list
    } catch (error) {
      toast.error("Error saving admin");
    }
  };

  // Handle Delete Admin
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this admin?")) {
      try {
        await axios.delete(`${baseUrl}/api/Admin/${id}`);
        toast.success("Admin deleted successfully");
        fetchAdmins(); // Refresh admin list
      } catch (error) {
        toast.error("Error deleting admin");
      }
    }
  };

  // Open Modal for Add
  const handleAddAdmin = () => {
    setCurrentAdmin({
      id: 0,
      schoolId: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      createdBy: loggedUser,
    updatedBy: loggedUser,
    });
    setIsEdit(false);
    setShowModal(true);
  };

  // Open Modal for Edit
  const handleEditAdmin = (admin) => {
    setCurrentAdmin(admin);
    setIsEdit(true);
    setShowModal(true);
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>Manage Admins</h2>
          <Button variant="primary" onClick={handleAddAdmin}>
            Add New Admin
          </Button>
        </div>

        {/* Admins Table */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>School Name</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <tr key={admin.id}>
                <td>{admin.id}</td>
                <td>
                  {schools.find((school) => school.schoolId === admin.schoolId)?.schoolOrCollegeName || "N/A"}
                </td>
                <td>{admin.firstName}</td>
                <td>{admin.lastName}</td>
                <td>{admin.email}</td>
                <td>{admin.phone}</td>
                <td>
                  <Button variant="warning" onClick={() => handleEditAdmin(admin)}>
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    className="ml-2"
                    onClick={() => handleDelete(admin.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Modal for Add/Edit Admin */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{isEdit ? "Edit Admin" : "Add Admin"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="schoolId">
                <Form.Label>School</Form.Label>
                <Form.Control
                  as="select"
                  value={currentAdmin.schoolId}
                  onChange={(e) =>
                    setCurrentAdmin({ ...currentAdmin, schoolId: e.target.value })
                  }
                  required
                >
                  <option value="">Select a School</option>
                  {schools.map((school) => (
                    <option key={school.schoolId} value={school.schoolId}>
                      {school.schoolOrCollegeName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={currentAdmin.firstName}
                  onChange={(e) =>
                    setCurrentAdmin({ ...currentAdmin, firstName: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={currentAdmin.lastName}
                  onChange={(e) =>
                    setCurrentAdmin({ ...currentAdmin, lastName: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={currentAdmin.email}
                  onChange={(e) =>
                    setCurrentAdmin({ ...currentAdmin, email: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  value={currentAdmin.phone}
                  onChange={(e) =>
                    setCurrentAdmin({ ...currentAdmin, phone: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={currentAdmin.password}
                  onChange={(e) =>
                    setCurrentAdmin({ ...currentAdmin, password: e.target.value })
                  }
                  required={!isEdit} // Password is required for adding a new admin
                  disabled={isEdit} // Disable password field when editing
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                {isEdit ? "Update" : "Add"}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <ToastContainer />
      </div>
      <Footer />
    </>
  );
};

export default ManageAdmin;
